import { useState, React } from 'react';
import { Card, CardContent, Grid, Typography, Button, Box, Link } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useCSVReader } from 'react-papaparse';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const UploadFileCard = ({ onCsvUpload, onCsvRemove }) => {
    const { CSVReader } = useCSVReader();
    const [fileUploaded, setFileUploaded] = useState(null);

    const styles = {
        csvReader: {
            display: 'flex',
            flexDirection: 'column',
            margin: 10,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
        },
        browseFile: {
            width: '112px'
        },
        acceptedFile: {
            width: 'auto'
        },
        remove: {
            borderRadius: 0,
            padding: '0 20px'
        },
        progressBarBackgroundColor: {
            backgroundColor: '#2196f3'
        }
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container direction="column" spacing={2} justifyContent="center" alignItems="center">
                    <CloudUploadIcon fontSize="large" color="primary" />
                    <Typography variant="subtitle" sx={{ opacity: 0.9, marginTop: 1 }} color="inherit">
                        Click "Upload File" to upload a CSV file containing your list of keywords.
                    </Typography>
                    <Typography variant="subtitle" sx={{ opacity: 0.9 }} color="inherit">
                        We currently support keyword reports from{' '}
                        <Box component="span" fontWeight="bold">
                            Ahrefs
                        </Box>
                        ,
                        <Box component="span" fontWeight="bold">
                            {' '}
                            Semrush
                        </Box>
                        , and{' '}
                        <Box component="span" fontWeight="bold">
                            Google Keyword Planner
                        </Box>
                        .
                    </Typography>
                    <Typography variant="subtitle" sx={{ opacity: 0.9, marginTop: 1 }} color="inherit">
                        <Link href="https://app.topclusters.io/sample-reports" target="_blank" rel="noopener">
                            Click here
                        </Link>{' '}
                        to view sample reports in the supported formats.
                    </Typography>

                    <CSVReader
                        onUploadAccepted={(results) => {
                            setFileUploaded(true);
                            onCsvUpload(results);
                        }}
                    >
                        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
                            <>
                                <div style={styles.csvReader}>
                                    <Button type="button" variant="outlined" sx={styles.browseFile} {...getRootProps()}>
                                        Upload file
                                    </Button>
                                </div>
                                {fileUploaded && (
                                    <Card variant="outlined" sx={{ padding: 1, borderRadius: 8 }}>
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <UploadFileIcon sx={{ marginRight: 1 }} />
                                            <div style={styles.acceptedFile}>{acceptedFile && acceptedFile.name}</div>
                                            <HighlightOffIcon
                                                sx={{ cursor: 'pointer', marginLeft: 1 }}
                                                onClick={() => {
                                                    setFileUploaded(null);
                                                    onCsvRemove();
                                                }}
                                            />
                                        </Grid>
                                    </Card>
                                )}
                                <ProgressBar style={styles.progressBarBackgroundColor} />
                            </>
                        )}
                    </CSVReader>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default UploadFileCard;
