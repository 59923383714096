import { Grid, Button, Card, Typography, CardContent } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';

import NewProjectForm from 'ui-component/new-project-form/new-project-form.component';

const KeywordClustering = () => {
    return (
        <div>
            <Card sx={{ marginBottom: 2.5 }}>
                <CardContent>
                    <Typography variant="h5" fontSize="24px">
                        Keyword Clustering
                    </Typography>
                </CardContent>
            </Card>
            <MainCard title="New Project">
                <Grid margin={0} padding={0}>
                    <NewProjectForm />
                </Grid>
            </MainCard>
        </div>
    );
};

export default KeywordClustering;
