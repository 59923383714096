import { React, useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, Card, Typography, CardContent } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { UserContext } from 'contexts/user.context';

import { getAllProjects, getAllUsers, getAllOrders } from 'utils/firebase/firebase.utils';
import AllProjects from 'ui-component/all-project/all-projects.component';
import AllUsers from 'ui-component/all-users/all-users.component';
import AllOrders from 'ui-component/all-orders/all-orders.component';

const Admin = () => {
    const [isLoading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [users, setUsers] = useState([]);
    const [orders, setOrders] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedRowsData, setSelectedRowsData] = useState([]);
    const [selectedRowsDataUsers, setSelectedRowsDataUsers] = useState([]);
    const [selectedRowsDataOrders, setSelectedRowsDataOrders] = useState([]);
    const { currentUser } = useContext(UserContext);

    const getUserProjects = async () => {
        const userProjects = await getAllProjects();
        setProjects(userProjects);
        setLoading(false);
        return userProjects;
    };

    const getUsers = async () => {
        const allUsers = await getAllUsers();
        setUsers(allUsers);
        setLoading(false);
        return allUsers;
    };

    const getOrders = async () => {
        const allOrders = await getAllOrders();
        setOrders(allOrders);
        setLoading(false);
        return allOrders;
    };

    useEffect(() => {
        getUserProjects();
        getUsers();
        getOrders();
        setLoading(false);
    }, []);
    return (
        <div>
            <Card sx={{ marginBottom: 2.5 }}>
                <CardContent>
                    <Typography variant="h5" fontSize="24px">
                        Admin
                    </Typography>
                </CardContent>
            </Card>
            <MainCard title="All Projects">
                <Grid margin={0} padding={0}>
                    <AllProjects
                        projects={projects}
                        setProjects={setProjects}
                        isLoading={isLoading}
                        selectedRowsData={selectedRowsData}
                        setSelectedRowsData={setSelectedRowsData}
                    />
                </Grid>
            </MainCard>
            <MainCard title="All Users">
                <Grid margin={0} padding={0}>
                    <AllUsers users={users} isLoading={isLoading} setSelectedRowsDataUsers={setSelectedRowsDataUsers} />
                </Grid>
            </MainCard>
            <MainCard title="All Orders">
                <Grid margin={0} padding={0}>
                    <AllOrders orders={orders} isLoading={isLoading} setSelectedRowsDataOrders={setSelectedRowsDataOrders} />
                </Grid>
            </MainCard>
        </div>
    );
};

export default Admin;
