// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// constant
const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const support = {
    id: 'support-pagegroup',
    type: 'group',
    children: [
        {
            id: 'support-page',
            title: 'Support',
            type: 'item',
            url: '/support-page',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'faq-page',
            title: 'FAQ',
            type: 'item',
            url: '/faq-page',
            icon: icons.IconHelp,
            breadcrumbs: false
        }
    ]
};

export default support;
