// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { UserContext } from 'contexts/user.context';
import { useContext } from 'react';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { currentUser } = useContext(UserContext);

    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;

            case 'admin':
                if (currentUser.email === 'joffenbratli@gmail.com') {
                    return <NavGroup key={item.id} item={item} />;
                } else {
                    return;
                }
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
