import { Grid, Button, Card, Typography, CardContent, ButtonBase, Box } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { ReactComponent as CheckoutIcon } from '../../assets/images/checkout-success.svg';

const CheckoutSuccess = () => {
    return (
        <div>
            {/* <Card sx={{ marginBottom: 2.5 }}>
                <CardContent>
                    <Typography variant="h5" fontSize="24px">
                        Checkout Successful
                    </Typography>
                </CardContent>
            </Card> */}
            <MainCard title="Congratulations, your payment has been successful!">
                <Grid container direction="column" margin={0} padding={0} alignItems="center" justifyContent="center">
                    <CheckoutIcon height={200} width={200} />
                    <Typography variant="body1" fontSize="16px" textAlign="center" p={1}>
                        Please note that it may take up to 5 minutes for your credits to appear in your account. Once they are added, you
                        can start using them immediately to enhance your keyword research and optimize your content.{' '}
                    </Typography>
                    <Typography variant="body1" fontSize="16px" textAlign="center" p={1}>
                        Thank you for choosing our product, and we hope that it helps you achieve your goals. If you have any questions or
                        concerns, please don't hesitate to contact our support team at help@topcluster.io.{' '}
                    </Typography>
                    <Typography variant="body1" fontSize="16px" textAlign="center" p={1}>
                        Happy clustering! 😃
                    </Typography>
                </Grid>
            </MainCard>
        </div>
    );
};

export default CheckoutSuccess;
