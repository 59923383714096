// material-ui
import { Typography, Box, Link } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| SUPPORT PAGE ||============================== //

const SampleReportsPage = () => (
    <MainCard title="Keyword Report Samples">
        <Typography variant="body2" sx={{ opacity: 0.9 }} color="black" fontSize={18}>
            We support keyword lists exported from{' '}
            <Box component="span" fontWeight="bold">
                Ahrefs
            </Box>
            ,
            <Box component="span" fontWeight="bold">
                {' '}
                Semrush
            </Box>
            , and{' '}
            <Box component="span" fontWeight="bold">
                Google Keyword Planner
            </Box>
            .
        </Typography>
        <Typography> </Typography>

        <Typography variant="body2" color="black" sx={{ opacity: 0.8 }} fontSize={16} marginBottom={1}>
            Below are a few sample reports from Ahrefs, Semrush and Google Keyword Planner to help you understand the format of the CSV file
            that you should upload to cluster your keywords:
        </Typography>
        <Typography variant="body2" fontSize={16}>
            <Link
                href="https://res.cloudinary.com/dqdrb5vyi/raw/upload/v1680197846/TopClusters/ahrefs_matchin_terms_sample_avtjfy.csv"
                target="_blank"
                rel="noopener"
            >
                Ahrefs Keyword Report Sample
            </Link>
        </Typography>
        <Typography variant="body2" fontSize={16}>
            <Link
                href="https://res.cloudinary.com/dqdrb5vyi/raw/upload/v1680197853/TopClusters/semrush_sample_report_lwkajr.csv"
                target="_blank"
                rel="noopener"
            >
                Semrush Keyword Report Sample
            </Link>
        </Typography>
        <Typography variant="body2" fontSize={16}>
            <Link
                href="https://res.cloudinary.com/dqdrb5vyi/raw/upload/v1680197858/TopClusters/google_keyword_planner_sample_ythvzp.csv"
                target="_blank"
                rel="noopener"
            >
                Google Keyword Planner Keyword Report Sample
            </Link>
        </Typography>
        <Typography> </Typography>
        <Typography variant="body2" sx={{ opacity: 0.9 }} color="black" fontSize={16} fontWeight="bold">
            Custom Keyword Lists
        </Typography>
        <Typography variant="body2" fontSize={14}>
            To upload your own custom keyword list not from any of these sites, you need to have at least a column called{' '}
            <Box component="span" fontWeight="bold">
                "Keyword"
            </Box>{' '}
            in your csv file. If you're using your own list of keywords, make sure to include this column name, or clustering will fail.
            While a{' '}
            <Box component="span" fontWeight="bold">
                "Volume"
            </Box>{' '}
            column is not required, clusters won't be sorted by total monthly volume if the "Volume" column is not present. You will also
            not be able to see the total monthly volume for each cluster.
        </Typography>
        <Typography> </Typography>

        <Typography variant="body2" fontSize={16}>
            <Link
                href="https://res.cloudinary.com/dqdrb5vyi/raw/upload/v1680710659/custom_sample_report_keyword_volume_difficulty_gwxrml.csv"
                target="_blank"
                rel="noopener"
            >
                Custom Keyword List Sample 1
            </Link>
        </Typography>
        <Typography variant="body2" fontSize={16}>
            <Link
                href="https://res.cloudinary.com/dqdrb5vyi/raw/upload/v1680710595/custom_sample_report_keyword_volume_apsgjo.csv"
                target="_blank"
                rel="noopener"
            >
                Custom Keyword List Sample 2
            </Link>
        </Typography>

        <Typography variant="body2" fontSize={16}>
            <Link
                href="https://res.cloudinary.com/dqdrb5vyi/raw/upload/v1680710635/custom_sample_report_keyword_zkwcy9.csv"
                target="_blank"
                rel="noopener"
            >
                Custom Keyword List Sample 3
            </Link>
        </Typography>

        <Typography> </Typography>
        <Typography variant="body2" fontSize={16}>
            Please don't hesitate to contact us at <span style={{ color: 'black' }}>help@topclusters.io</span> if you need any further
            assistance or have any questions.
        </Typography>
        <Typography> </Typography>
    </MainCard>
);

export default SampleReportsPage;
