import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 },
    '& .MuiCardHeader-title': { color: 'white', fontSize: '16px', marginBottom: '8px' },
    '& .MuiCardHeader-subheader': { color: 'white' },
    backgroundColor: 'primary.main'
};

// ==============================|| CUSTOM Cluster CARD ||============================== //

const ClusterCardBase = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            secondary,
            shadow,
            sx = {},
            title,
            volume,
            numKeywordsInCluster,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.primary[200] + 75,
                    ':hover': {
                        boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
                    },
                    ...sx
                }}
            >
                {/* card header and action */}
                {title && (
                    <CardHeader
                        sx={{ ...headerSX, padding: 2 }}
                        title={title}
                        subheader={
                            <div>
                                <Typography variant="body2" sx={{ color: 'white' }}>
                                    Volume: {volume}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'white' }}>
                                    Keywords: {numKeywordsInCluster}
                                </Typography>
                            </div>
                        }
                        action={secondary}
                    />
                )}

                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent sx={{ contentSX, padding: 2 }} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

ClusterCardBase.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default ClusterCardBase;
