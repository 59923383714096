import { useState } from 'react';

import { createProject2, updateProject, chargeUserCredits } from 'utils/firebase/firebase.utils';
import axios from 'axios';

function CloudFunction(reqBody) {
    const [error, setError] = useState(null);

    const keywordClustering = async (userId, userEmail, numKeywords, projectName, location, language, selectedCard) => {
        const createdAt = Date.now();
        let client;
        if (selectedCard === 0) {
            // NLP algorithm
            const algorithm = 'nlp';
            client = axios.create({
                baseURL: `${process.env.REACT_APP_KW_CLUSTERING_SEMANTIC_CLUSTERING_CLOUD_FUNCTION_URL}?userId=${userId}&createdAt=${createdAt}&credits=${numKeywords}`
            });
            try {
                await createProject2(userId, userEmail, createdAt, numKeywords, projectName, 'In progress', algorithm);
                let response = await client.post('', reqBody);
            } catch (error) {
                setError(error);
                console.log(error);
            }

            console.log('NLP algorithm');
        } else if (selectedCard === 1) {
            // Google SERP algorithm
            const algorithm = 'serp';
            client = axios.create({
                baseURL: `${process.env.REACT_APP_KW_CLUSTERING_COMMON_LINKS_CLOUD_FUNCTION_URL}?language=${language}&country=${location}&userId=${userId}&createdAt=${createdAt}&credits=${numKeywords}`
            });
            try {
                await createProject2(userId, userEmail, createdAt, numKeywords, projectName, 'In progress', algorithm);
                let response = await client.post('', reqBody);
            } catch (error) {
                setError(error);
                console.log(error);
            }
            console.log('Google SERP algorithm');
        }
    };

    return { error, keywordClustering };
}

export default CloudFunction;
