import { Grid, Card, Typography, CardContent } from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';
import GeneralAccountSettings from 'ui-component/general-account-settings/general-account-settings.component';
import PaymentForm from 'ui-component/payment-form/payment-form.component';
const BuyCredits = () => {
    return (
        <>
            <Card sx={{ marginBottom: 2.5 }}>
                <CardContent>
                    <Typography variant="h5" fontSize="24px">
                        Buy Credits
                    </Typography>
                </CardContent>
            </Card>
            <MainCard title="Pricing Options">
                <Grid margin={0} padding={0}>
                    <PaymentForm />
                </Grid>
            </MainCard>
        </>
    );
};

export default BuyCredits;
