import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { Grid, Button, Typography, Box } from '@mui/material';
import ClusterCardBase from 'ui-component/cards/ClusterCardBase';
import { useTheme } from '@mui/material/styles';
import Loader from 'ui-component/Loader';
import { margin } from '@mui/system';

const ClusterCards = ({ data, isLoading }) => {
    const initialNumKeywords = 3;

    const [showAllKeywords, setShowAllKeywords] = useState(false);
    const [numKeywords, setNumKeywords] = useState(initialNumKeywords);
    const [selectedClusterId, setSelectedClusterId] = useState(null);

    const theme = useTheme();

    const handleShowAllKeywords = (numClusterKeywords, id) => {
        // setShowAllKeywords((prevState) => ({
        //     ...prevState,
        //     [id]: true
        // }));
        setSelectedClusterId(id);
        setNumKeywords(numClusterKeywords);
    };

    const handleShowLessKeywords = (id) => {
        // setShowAllKeywords((prevState) => ({
        //     ...prevState,
        //     [id]: false
        // }));
        setNumKeywords(initialNumKeywords);
        setSelectedClusterId(null);
    };

    if (isLoading) return <Loader />;

    if (data) {
        const clusterData = data.map((item, index) => ({
            id: index,
            title: item.Cluster,
            keywords: item.Keywords.split(', '),
            totalMonthlyVolume: item['Total monthly volume']
        }));
        return (
            <>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        gridTemplateColumns: 'repeat(1, 1fr)',
                        [theme.breakpoints.up('xs')]: { gridTemplateColumns: 'repeat(1, 1fr)' },
                        [theme.breakpoints.up('s')]: { gridTemplateColumns: 'repeat(2, 1fr)' },
                        [theme.breakpoints.up('m')]: { gridTemplateColumns: 'repeat(3, 1fr)' },
                        [theme.breakpoints.up('l')]: { gridTemplateColumns: 'repeat(4, 1fr)' },
                        [theme.breakpoints.up('xl')]: { gridTemplateColumns: 'repeat(4, 1fr)' }
                    }}
                >
                    {clusterData.map((cluster) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={cluster.title}>
                            <ClusterCardBase
                                key={cluster.title}
                                title={cluster.title.charAt(0).toUpperCase() + cluster.title.slice(1)}
                                volume={cluster.totalMonthlyVolume}
                                numKeywordsInCluster={cluster.keywords.length}
                            >
                                <Grid margin={0} padding={0}>
                                    {selectedClusterId === cluster.id
                                        ? cluster.keywords.slice(0, numKeywords).map((keyword, index) => (
                                              <Typography
                                                  variant="body2"
                                                  key={keyword}
                                                  sx={{
                                                      fontSize: '12px',
                                                      paddingY: 1,
                                                      borderBottom:
                                                          index < cluster.keywords.length - 1
                                                              ? '1px dotted ' + theme.palette.grey[300]
                                                              : 'none'
                                                  }}
                                              >
                                                  {keyword}
                                              </Typography>
                                          ))
                                        : cluster.keywords.slice(0, initialNumKeywords).map((keyword, index) => (
                                              <Typography
                                                  variant="body2"
                                                  key={keyword}
                                                  sx={{
                                                      fontSize: '12px',
                                                      paddingY: 1,
                                                      borderBottom:
                                                          index < cluster.keywords.length - 1
                                                              ? '1px dotted ' + theme.palette.grey[300]
                                                              : 'none'
                                                  }}
                                              >
                                                  {keyword}
                                              </Typography>
                                          ))}

                                    {cluster.keywords.length > initialNumKeywords &&
                                        (selectedClusterId === cluster.id ? (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '4px' }}>
                                                <Button
                                                    color="primary"
                                                    onClick={() => handleShowLessKeywords(cluster.id)}
                                                    sx={{ fontSize: '12px' }}
                                                >
                                                    Show less
                                                </Button>
                                            </Box>
                                        ) : (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '4px' }}>
                                                <Button
                                                    color="primary"
                                                    onClick={() => handleShowAllKeywords(cluster.keywords.length, cluster.id)}
                                                    sx={{ fontSize: '12px' }}
                                                >
                                                    Show all keywords
                                                </Button>
                                            </Box>
                                        ))}
                                </Grid>
                            </ClusterCardBase>
                        </Grid>
                    ))}
                </Grid>
            </>
        );
    } else {
        <div>Error fetching clusters</div>;
    }
};

export default ClusterCards;
