import { React, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserDoc } from 'utils/firebase/firebase.utils';
import { UserContext } from 'contexts/user.context';
import Loader from 'ui-component/Loader';
import { useTheme } from '@mui/material/styles';
import { Chip, Avatar } from '@mui/material';
import TollIcon from '@mui/icons-material/Toll';

const CreditsSection = () => {
    const theme = useTheme();

    const { currentUser } = useContext(UserContext);
    const [userDocRef, setUserDocRef] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const getUserDocRef = async () => {
            const userDocData = currentUser ? await getUserDoc(currentUser) : null;
            setUserDocRef(userDocData);
            // console.log(`userDocRef: ${userDocData}`);
            setLoading(false);
        };
        if (!userDocRef) {
            // Check if userDocRef is not set
            getUserDocRef();
        }
        // console.log(currentUser);
    }, [currentUser, userDocRef]); // Add currentUser to the dependency array

    if (isLoading) return <Loader />;
    return (
        <div>
            <Chip
                sx={{
                    ml: 1,
                    mr: 1,
                    mt: 1,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    },
                    paddingLeft: '4px',
                    height: '36px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={<TollIcon />}
                label={<p>{userDocRef?.credits || 0}</p>}
                variant="outlined"
                // ref={anchorRef}
                // aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                // onClick={handleToggle}
                color="primary"
            />
        </div>
    );
};

export default CreditsSection;
