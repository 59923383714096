import { React, useState, useContext, useEffect } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { UserContext } from '../../contexts/user.context';
import { DataGrid } from '@mui/x-data-grid';
import Loader from 'ui-component/Loader';

const PaymentHistory = () => {
    const stripe = useStripe();
    const [payments, setPayments] = useState([]);
    const [currency, setCurrency] = useState([]);
    const { currentUser } = useContext(UserContext);
    const [isLoading, setLoading] = useState(true);

    const paymentsHandler = async () => {
        // e.preventDefault();
        if (!stripe) {
            console.log('Stripe not working');
            return;
        }

        // const url = 'https://stripe-htphw67dca-uc.a.run.app';
        const url = `${process.env.REACT_APP_STRIPE_CLOUD_FUNCTION_URL}/payment-history`;
        // const url = 'http://localhost:3000/payment-history';
        const response = await fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: currentUser.uid
            })
        });
        const resBody = await response.json();
        // console.log(resBody.paymentIntents.data);

        const paymentIntent = resBody.paymentIntents.data.map((paymentIntent) => paymentIntent);
        setPayments(paymentIntent);
        setCurrency(paymentIntent[0].currency);
        setLoading(false);
    };

    const columns = [
        {
            field: 'created',
            headerName: 'Date',
            minWidth: 120,
            flex: 2,
            renderCell: (params) => {
                const dateTime = new Date(params.row.created);
                return <div>{dateTime.toString()}</div>;
            }
        },
        {
            field: 'amount',
            headerName: 'Amount',
            minWidth: 120,
            flex: 1,
            renderCell: (params) => {
                return <div>{(params.row.amount / 100).toLocaleString('en-US', { style: 'currency', currency: currency })}</div>;
            }
        },
        { field: 'status', headerName: 'Status', minWidth: 120, flex: 1 }
    ];

    useEffect(() => {
        paymentsHandler();
    }, []);

    if (isLoading) return <Loader />;

    return (
        <>
            <div style={{ width: '100%' }}>
                <DataGrid
                    sx={{
                        border: 0,
                        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                            outline: 'none !important'
                        },
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
                            outline: 'none'
                        },
                        '.MuiDataGrid-columnSeparator': {
                            display: 'none'
                        },
                        '.MuiDataGrid-columnHeader--sortable': {
                            outline: 'none !important'
                        },
                        '.MuiDataGrid-columnHeaderTitle': {
                            color: 'text.dark'
                        }
                    }}
                    autoHeight
                    rows={payments}
                    columns={columns}
                    getRowId={(row) => row.id}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                />
            </div>
        </>
    );
};

export default PaymentHistory;
