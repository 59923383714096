import { React, useState, useContext, CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    FormControl,
    FormHelperText,
    OutlinedInput,
    InputLabel,
    Stack,
    Typography,
    Grid,
    Box,
    Button,
    Divider,
    CardContent,
    Select,
    MenuItem,
    Snackbar,
    Alert
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FileUpload from 'react-material-file-upload';
import CloudFunction from 'utils/cloud-function/CloudFunction';
import Papa from 'papaparse';
import csvDownload from 'json-to-csv-export';
import { createProject, createProject2, createProject3, checkUserCredits } from 'utils/firebase/firebase.utils';
import { UserContext } from '../../contexts/user.context';
import { countryCodes, languageCodes } from 'utils/constants/constants';
import EarningCard from 'ui-component/cards/Skeleton/EarningCard';
import CardSecondaryAction from 'ui-component/cards/CardSecondaryAction';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';
import AlgorithmCard from 'ui-component/cards/AlgorithmCard';
import { usePapaParse } from 'react-papaparse';
import { useCSVReader } from 'react-papaparse';
import UploadFileCard from 'ui-component/upload-file.component.jsx/upload-file.component';

const NewProjectForm = () => {
    const theme = useTheme();
    const [files, setFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [noAlgorithmSelected, setNoAlgorithmSelected] = useState(false);
    const [serpKwExceeded, setSerpKwExceeded] = useState(false);
    const [nlpKwExceeded, setNlpKwExceeded] = useState(false);
    const [nlpKwTooLow, setNlpKwTooLow] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [notEnoughCredits, setNotEnoughCredits] = useState(false);
    const [numRows, setNumRows] = useState(0);
    const [selectedCard, setSelectedCard] = useState(null);
    const [csvResults, setCsvResults] = useState();

    // const { values, setFieldValue } = useFormikContext();
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();

    const { readString } = usePapaParse();
    const { CSVReader } = useCSVReader();

    const renderCountryCodes = () => {
        return Object.entries(countryCodes).map(([key, value]) => {
            return (
                <MenuItem key={key} value={value}>
                    {key}
                </MenuItem>
            );
        });
    };

    const renderLanguageCodes = () => {
        return Object.entries(languageCodes).map(([key, value]) => {
            return (
                <MenuItem key={key} value={value}>
                    {key}
                </MenuItem>
            );
        });
    };

    // handling csv data and calling cloud function
    const [parsedData, setParsedData] = useState(null);
    const { data, loading, error, keywordClustering } = CloudFunction(parsedData);

    const runKeywordClustering = async (projectName, country, language) => {
        if (selectedCard === null) {
            setNoAlgorithmSelected(true);
            return;
        }
        if (files.length === 0) {
            setOpen(true);
            return;
        }
        if (selectedCard === 1 && numRows > 500) {
            setSerpKwExceeded(true);
            return;
        }
        if (selectedCard === 0 && numRows > 20000) {
            setNlpKwExceeded(true);
            return;
        }
        if (selectedCard === 0 && numRows < 50) {
            setNlpKwTooLow(true);
            return;
        }
        setInProgress(true);
        // console.log(`numRows: ${numRows}`);
        const resultUserCredits = await checkUserCredits(numRows, currentUser.uid);
        if (resultUserCredits === 'sufficient funds') {
            keywordClustering(currentUser.uid, currentUser.email, numRows, projectName, country, language, selectedCard);
            navigate('/dashboard');
        } else {
            setNotEnoughCredits(true);
            console.log('Not enough credits');
        }
    };

    // const handleFileChangeOld = (files) => {
    //     Papa.parse(files[0], {
    //         header: true,
    //         skipEmptyLines: true,
    //         complete: function (results) {
    //             console.log('results');
    //             console.log(results);
    //             handleFileChange(results);
    //         }
    //     });
    // };

    const handleRemoveFile = () => {
        setParsedData(null);
        setNumRows(0);
        // console.log(parsedData);
        setFiles([]);
    };

    const handleFileChange = (results) => {
        setFiles([results]);
        setCsvResults(results);
        console.log('---------------------------');
        console.log(results.data);
        const originalArray = results.data;
        console.log('---------------------------');
        const finalArray = [];

        const filteredArray = [];

        for (let i = 0; i < originalArray.length; i++) {
            const arr = originalArray[i];
            if (arr.includes('Keyword')) {
                filteredArray.push(arr);
                for (let j = i + 1; j < originalArray.length; j++) {
                    filteredArray.push(originalArray[j]);
                }
                break;
            }
        }

        console.log(filteredArray);

        for (let i = 1; i < filteredArray.length; i++) {
            const obj = {};
            for (let j = 0; j < filteredArray[i].length; j++) {
                obj[filteredArray[0][j]] = filteredArray[i][j];
            }
            finalArray.push(obj);
        }
        console.log(finalArray);

        // delete key called "#"
        finalArray.forEach((obj) => delete obj['#']);
        finalArray.forEach((obj) => delete obj['Term group']);

        console.log(finalArray);

        setParsedData(finalArray);
        const rows = finalArray.length;
        setNumRows(rows);
        // console.log(`Number of rows: ${rows}`);
    };

    if (loading) return <h1> LOADING...</h1>;

    if (error) return <p> {JSON.stringify(error)}</p>;

    return (
        <>
            <Formik
                initialValues={{
                    projectName: '',
                    country: 'us',
                    language: 'en',
                    files: []
                }}
                validationSchema={Yup.object().shape({
                    projectName: Yup.string()
                        .required('Project name is required')
                        .min(2, 'Project name must be at least 2 characters')
                        .max(50, 'Project name must be 50 characters or less')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    console.log(values);
                    runKeywordClustering(values.projectName, values.country, values.language);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid sx={{ marginBottom: 2 }}>
                            <FormControl fullWidth sx={{ marginBottom: 2 }} error={Boolean(touched.projectName && errors.projectName)}>
                                <Typography sx={{ marginBottom: 1 }}>Project name</Typography>
                                <InputLabel shrink="true" htmlFor="outlined-project-name"></InputLabel>
                                <OutlinedInput
                                    id="outlined-project-name"
                                    placeholder="Enter the name of the project"
                                    value={values.projectName}
                                    name="projectName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    inputProps={{}}
                                />
                                {touched.projectName && errors.projectName && (
                                    <FormHelperText error id="standard-weight-helper-text-project-name">
                                        {errors.projectName}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <Typography variant="h5" marginBottom={1}>
                                Algorithm
                            </Typography>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '34px' }}>
                                <AlgorithmCard
                                    style={{ gridColumn: '1 / 2' }}
                                    selected={selectedCard === 0}
                                    onClick={() => setSelectedCard(0)}
                                    title="Natural Language Processing (NLP)"
                                    speed="Fastest"
                                    limit="Up to 20,000 keywords at a time"
                                    description="Keyword clustering using NLP involves utilizing Natural Language Processing techniques to identify semantic relationships between keywords. NLP-based approaches can identify more complex relationships between words that are not immediately obvious, such as identifying that 'World Cup' and 'Soccer' are related. This algorithm leverages state-of-the-art deep learning techniques, and uses pre-trained models that have been trained at scale on large amounts of data. This approach is faster than the Google SERP-based approach since we don't need to run every keyword through the Google SERP, resulting in quicker keyword clustering."
                                />
                                <AlgorithmCard
                                    style={{ gridColumn: '1 / 2' }}
                                    selected={selectedCard === 1}
                                    onClick={() => setSelectedCard(1)}
                                    title="Google SERP"
                                    speed=""
                                    limit="Up to 500 keywords at a time"
                                    description="Keyword clustering using Google SERP involves analyzing search engine results to group related keywords together. This process involves searching for each keyword on Google and analyzing the top 10 search results to identify matching URLs. Keywords that share three or more matching URLs are then clustered together. This approach provides a higher level of accuracy, eliminates irrelevant search queries from being clustered, and generally results in smaller, tighter clusters. The primary keyword in a cluster is the keyword with the most search volume."
                                />
                            </div>
                            {selectedCard === 1 && (
                                <div>
                                    <Typography variant="h5" marginY={1}>
                                        Specify the country and language from which to base the Google search
                                    </Typography>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
                                        <FormControl fullWidth>
                                            <Typography sx={{ marginBottom: 1 }}>Location</Typography>
                                            <InputLabel shrink="true" htmlFor="outlined-country"></InputLabel>
                                            <Select
                                                id="outlined-country"
                                                name="country"
                                                value={values.country}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                {renderCountryCodes()}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <Typography sx={{ marginBottom: 1 }}>Language</Typography>
                                            <InputLabel shrink="true" htmlFor="outlined-language"></InputLabel>
                                            <Select
                                                id="outlined-language"
                                                name="language"
                                                value={values.language}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                {renderLanguageCodes()}
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </div>
                            )}
                        </Grid>
                        <div>
                            <Typography sx={{ marginBottom: 1 }}>Upload CSV file</Typography>

                            {/* <FileUpload
                                value={files}
                                onChange={(files) => {
                                    setFiles(files);
                                    console.log(files);
                                    if (files.length === 0) {
                                        setNumRows(0);
                                    }
                                    handleFileChangeOld(files);
                                }}
                                buttonText="Upload File"
                                multiple={false}
                                title={
                                    <>
                                        <Typography color="inherit">
                                            Drag 'n' drop, or click to upload a CSV file containing your list of keywords.
                                        </Typography>
                                        <Typography color="inherit">We currently support reports from Ahrefs and SEMRush.</Typography>
                                    </>
                                }
                                sx={{
                                    borderRadius: '12px',
                                    paddingY: 2,
                                    paddingX: 1
                                }}
                                buttonProps={{ variant: 'outlined' }}
                            /> */}
                        </div>
                        <UploadFileCard onCsvUpload={handleFileChange} onCsvRemove={handleRemoveFile} />

                        <CardContent sx={{ px: 0 }}>
                            <Divider />
                        </CardContent>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography color="secondary" fontSize="18px">
                                Total cost:<span> </span>
                                <Typography component="span" color="secondary" fontWeight="bold" fontSize="18px">
                                    {numRows || '0'} credits
                                </Typography>
                            </Typography>
                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}
                            <Button
                                disabled={inProgress}
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{ borderWidth: '2px', borderRadius: '6px' }}
                            >
                                Start Clustering
                            </Button>
                            <Snackbar
                                open={open}
                                autoHideDuration={6000}
                                onClose={() => setOpen(false)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <Alert onClose={() => setOpen(false)} severity="error" sx={{ width: '100%' }}>
                                    Please upload a CSV file
                                </Alert>
                            </Snackbar>
                            <Snackbar
                                open={noAlgorithmSelected}
                                autoHideDuration={6000}
                                onClose={() => setNoAlgorithmSelected(false)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <Alert onClose={() => setNoAlgorithmSelected(false)} severity="error" sx={{ width: '100%' }}>
                                    Please select an algorithm
                                </Alert>
                            </Snackbar>
                            <Snackbar
                                open={nlpKwExceeded}
                                autoHideDuration={6000}
                                onClose={() => setNlpKwExceeded(false)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <Alert onClose={() => setNlpKwExceeded(false)} severity="error" sx={{ width: '100%' }}>
                                    Your upload exceeds 20,000 keywords. Please upload less than 20,000 keywords when using the NLP
                                    algorithm.
                                </Alert>
                            </Snackbar>
                            <Snackbar
                                open={nlpKwTooLow}
                                autoHideDuration={6000}
                                onClose={() => setNlpKwTooLow(false)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <Alert onClose={() => setNlpKwTooLow(false)} severity="error" sx={{ width: '100%' }}>
                                    Please upload a minimum of 50 keywords when using the NLP algorithm.
                                </Alert>
                            </Snackbar>
                            <Snackbar
                                open={serpKwExceeded}
                                autoHideDuration={6000}
                                onClose={() => setSerpKwExceeded(false)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <Alert onClose={() => setSerpKwExceeded(false)} severity="error" sx={{ width: '100%' }}>
                                    Your upload exceeds 500 keywords. Please upload less than 500 keywords when using the Google SERP
                                    algorithm.
                                </Alert>
                            </Snackbar>
                            <Snackbar
                                open={notEnoughCredits}
                                autoHideDuration={6000}
                                onClose={() => setNotEnoughCredits(false)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <Alert onClose={() => setNotEnoughCredits(false)} severity="error" sx={{ width: '100%' }}>
                                    Insufficient funds. You don't have enough credits.
                                </Alert>
                            </Snackbar>
                        </Stack>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default NewProjectForm;
