import { Box, Button, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';
import { React, useState } from 'react';
import { useSelector } from 'react-redux';

const PricingOption = ({ name, price, prodId, features, checkoutHandler }) => {
    const theme = useTheme();
    const [isLoading, setLoading] = useState(false);
    const customization = useSelector((state) => state.customization);

    const isPro = name === 'Pro';

    return (
        <Grid item xs={12} sm={6} md={3} lg={3}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
                paddingX={4}
                paddingTop={4}
                sx={{
                    border: isPro ? '2px solid' : 'none',
                    borderColor: theme.palette.grey[600] + 75,
                    borderRadius: `${customization.borderRadius}px`,
                    background: isPro ? theme.palette.grey[200] : 'none'
                }}
            >
                {isPro ? (
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography variant="h3">{name}</Typography>
                        <Box padding={1}></Box>

                        <Typography
                            sx={{
                                backgroundColor: theme.palette.grey[50],
                                paddingY: '4px',
                                paddingX: '8px',
                                color: theme.palette.grey[900],
                                borderColor: theme.palette.grey[600] + 75,
                                borderRadius: `${customization.borderRadius}px`
                            }}
                            variant="p"
                        >
                            Most popular!
                        </Typography>
                    </Box>
                ) : (
                    <Typography variant="h3">{name}</Typography>
                )}

                <Typography variant="h1" paddingY={4}>
                    ${price}
                </Typography>

                <Button
                    onClick={() => {
                        setLoading(true);
                        checkoutHandler(name, Math.round(price * 100), prodId);
                    }}
                    variant="contained"
                    color="secondary"
                    sx={{ borderWidth: '2px', borderRadius: '6px', marginBottom: '24px', width: '100%' }}
                    disabled={isLoading}
                >
                    Buy
                </Button>

                <List>
                    {features.map((feature, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <CheckCircleIcon sx={{ color: theme.palette.success.dark, fontSize: '18px' }} />
                            </ListItemIcon>
                            <ListItemText primary={feature} />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Grid>
    );
};

export default PricingOption;
