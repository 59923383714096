import { getAnalytics } from 'firebase/analytics';

import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithRedirect,
    signInWithPopup,
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    sendPasswordResetEmail,
    sendEmailVerification,
    updatePassword,
    updateEmail,
    reauthenticateWithCredential,
    getAdditionalUserInfo
} from 'firebase/auth';
import {
    getFirestore,
    doc,
    getDoc,
    getDocs,
    setDoc,
    updateDoc,
    arrayUnion,
    addDoc,
    collection,
    query,
    where,
    deleteDoc,
    writeBatch
} from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseApp);
const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
    prompt: 'select_account'
});

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, googleProvider);
export const signInWithGoogleRedirect = () => signInWithRedirect(auth, googleProvider);

export const db = getFirestore();

export const createUserDocumentFromAuth = async (userAuth, additionalInformation = {}) => {
    if (!userAuth) return;

    const userDocRef = doc(db, 'users', userAuth.uid);

    const userSnapshot = await getDoc(userDocRef);

    if (!userSnapshot.exists()) {
        const { displayName, email } = userAuth;
        const createdAt = new Date();
        const credits = 100;
        try {
            await setDoc(userDocRef, {
                displayName,
                email,
                createdAt,
                credits,
                ...additionalInformation
            });
        } catch (error) {
            console.log('error creating the user', error.message);
        }
    }

    return userDocRef;
};

export const createAuthUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;
    await verificationEmail();
    return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;
    return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => {
    return await signOut(auth);
};

export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);

export const resetPassword = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        console.log('Password reset email sent');
        return 'Password reset email sent';
    } catch (error) {
        console.error(error);
        return error;
    }
};

// Method to send the user verification mail
export const verificationEmail = async () => {
    const user = auth.currentUser;
    if (user) {
        try {
            await sendEmailVerification(user);
            console.log(`User verified: ${auth.currentUser.emailVerified}`);
            console.log(`User email: ${auth.currentUser.email}`);
            console.log('Email Verification sent! Check your mail box');
            return 'Sent';
        } catch (error) {
            console.error(error);
            return 'Error';
        }
    } else {
        console.log('User not logged in.');
        return 'No user';
    }
};

export const changePassword = async (newPassword) => {
    if (!newPassword) return;
    return await updatePassword(auth.currentUser, newPassword);
};

export const changeEmail = async (userAuth, newEmail) => {
    if (!newEmail) return;
    return await updateEmail(userAuth, newEmail);
};
// TODO(you): prompt the user to re-provide their sign-in credentials
// const credential = promptForCredentials();

// export const reAuthUser = async () => {
//     reauthenticateWithCredential(auth.currentUser, credential).then(() => {
//         // User re-authenticated.
//       }).catch((error) => {
//         // An error ocurred
//         // ...
//       });
// }

export const getUserDocDisplayName = async (userAuth) => {
    const userDocRef = doc(db, 'users', userAuth.uid);
    try {
        const userSnapshot = await getDoc(userDocRef);
        // console.log(`userSnapshot.data().displayName: ${userSnapshot.data().displayName}`);
        return userSnapshot.data().displayName;
    } catch (error) {
        console.log('error getting userDoc', error);
    }
};

export const getUserDoc = async (userAuth) => {
    const userDocRef = doc(db, 'users', userAuth.uid);
    try {
        const userSnapshot = await getDoc(userDocRef);
        // console.log(`userSnapshot.data(): ${userSnapshot.data()}`);
        return userSnapshot.data();
    } catch (error) {
        console.log('error getting userDoc', error);
    }
};

export const updateUserDocDisplayNameEmail = async (userAuth, displayName, email) => {
    const userDocRef = doc(db, 'users', userAuth.uid);
    return await updateDoc(userDocRef, {
        displayName: displayName,
        email: email
    });
};

export const checkUserCredits = async (creditsToCharge, userId) => {
    const userDocRef = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userDocRef);
    // console.log(`userSnapshot.data().credits: ${userSnapshot.data().credits}`);
    if (userSnapshot.data().credits !== undefined) {
        const currentAmount = userSnapshot.data().credits;
        // console.log(`currentAmount: ${currentAmount}. creditsToCharge: ${creditsToCharge}`);
        if (currentAmount < creditsToCharge) {
            console.log('insufficient funds');
            return 'insufficient funds';
        } else {
            return 'sufficient funds';
        }
    } else {
        return 'insufficient funds';
        console.log('user has no credits in firebase.', error.message);
    }
};

export const createProject2 = async (userId, userEmail, createdAt, numKeywords, projectName, status, algorithm) => {
    // const createdAt = new Date();
    try {
        const docRef = await addDoc(collection(db, 'projects'), {
            userId: userId,
            userEmail: userEmail,
            createdAt: createdAt,
            numKeywords: numKeywords,
            projectName: projectName,
            status: status,
            data: [],
            algorithm: algorithm
        });
    } catch (error) {
        console.log('error creating project', error.message);
    }
};

export const getProjectsUser = async (userId) => {
    let docsArray = [];
    const q = query(collection(db, 'projects'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, ' => ', doc.data());
        docsArray.push(doc.data());
    });

    return docsArray;
};

export const getAllProjects = async () => {
    let docsArray = [];
    const q = query(collection(db, 'projects'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        docsArray.push(doc.data());
    });
    return docsArray;
};

export const getAllUsers = async () => {
    let docsArray = [];
    const q = query(collection(db, 'users'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        docsArray.push(doc.data());
    });
    return docsArray;
};

export const getAllOrders = async () => {
    let docsArray = [];
    const q = query(collection(db, 'orders'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        docsArray.push(doc.data().order);
    });
    return docsArray;
};

export const getProject = async (createdAt, userId) => {
    let projectRefArray = [];

    try {
        const q = query(collection(db, 'projects'), where('createdAt', '==', createdAt));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((docSnapshot) => {
            if (userId === docSnapshot.get('userId')) {
                projectRefArray.push(docSnapshot.data());
            }
        });
        console.log('projectRefArray');
        console.log(projectRefArray[0]);
        return projectRefArray[0];
    } catch (error) {
        console.log('error getting project', error);
    }
};

export const updateProject = async (data, createdAt, status, userId) => {
    const projectDocsRef = collection(db, 'projects');
    let projectRefArray = [];
    console.log('...1...');
    try {
        const q = query(projectDocsRef, where('createdAt', '==', createdAt));
        const querySnapshot = await getDocs(q);
        console.log('...2...');

        querySnapshot.forEach((docSnapshot) => {
            const projectRef = doc(db, 'projects', docSnapshot.id);
            if (userId === docSnapshot.get('userId')) {
                // console.log(userId + '===' + docSnapshot.get('userId'));
                projectRefArray.push(projectRef);
            }
            console.log('...3...');

            // console.log(doc.id, ' => ', doc.data());
        });
        console.log('...4...');

        await updateDoc(projectRefArray[0], {
            data: data,
            status: status
        });
        console.log('...5...');
    } catch (error) {
        console.log('error updating project', error.message);
    }
};

export const deleteProject = async (projectName, createdAt) => {
    try {
        const q = query(collection(db, 'projects'), where('createdAt', '==', createdAt));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((docSnapshot) => {
            const projectRef = doc(db, 'projects', docSnapshot.id);
            if (projectName === docSnapshot.get('projectName')) {
                deleteDoc(projectRef);
                // console.log(
                //     `Deleted project with projectName: ${docSnapshot.get('projectName')} & createdAt: ${docSnapshot.get('createdAt')}`
                // );
            }
        });
    } catch (error) {
        console.error('Error deleting project: ', error);
    }
};

export const deleteMultipleProjects = async (projects) => {
    try {
        // Get a new write batch
        const batch = writeBatch(db);

        // Loop through each project and add a delete operation to the batch
        for (const project of projects) {
            const q = query(collection(db, 'projects'), where('createdAt', '==', project.createdAt));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((docSnapshot) => {
                const projectRef = doc(db, 'projects', docSnapshot.id);
                batch.delete(projectRef);
            });
        }

        // Commit the batch
        await batch.commit();
        // console.log(`Deleted multiple projects: ${projects}}`);
    } catch (error) {
        console.error('Error deleting multiple project: ', error);
    }
};
