import { React, useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { Grid, Button, Card, Typography, CardContent, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { useTheme, makeStyles } from '@mui/material/styles';

// project imports
import Projects from 'ui-component/projects/projects.component';
import MainCard from 'ui-component/cards/MainCard';

import { getProjectsUser, deleteMultipleProjects } from 'utils/firebase/firebase.utils';

import { UserContext } from 'contexts/user.context';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = () => {
    const [isLoading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedRowsData, setSelectedRowsData] = useState([]);
    const { currentUser } = useContext(UserContext);
    const theme = useTheme();

    const navigate = useNavigate();

    const getUserProjects = async () => {
        const userProjects = await getProjectsUser(currentUser.uid);
        // console.log(userProjects);
        setProjects(userProjects);
        setLoading(false);
        return userProjects;
    };

    const refreshProjects = () => {
        setLoading(true);
        getUserProjects();
    };

    const handleDeleteMultipleProjectsClick = (e) => {
        e.stopPropagation();
        // console.log(selectedRowsData);
        // setCreatedAtToDelete(params.row.createdAt);
        // setProjectNameToDelete(params.row.projectName);
        setOpen(true);
    };

    const handleDeleteMultipleProjects = async () => {
        await deleteMultipleProjects(selectedRowsData);
        setProjects(
            projects.filter((project) => {
                return !selectedRowsData.includes(project);
            })
        );

        setOpen(false);
    };

    useEffect(() => {
        getUserProjects();
        setLoading(false);
    }, []);

    const handleClick = (id) => {
        // Do something with the id parameter
        // console.log(`Clicked on item with id ${id}`);
    };

    const menuItems = [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'group',
            children: [
                {
                    id: 'default',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/dashboard',
                    // icon: icons.IconDashboard,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'keyword-clustering',
            type: 'group',
            children: [
                {
                    id: 'keyword-clustering-page',
                    title: 'Keyword Clustering',
                    type: 'item',
                    url: '/keyword-clustering',
                    // icon: HubOutlinedIcon,
                    breadcrumbs: false
                }
            ]
        }
    ];

    return (
        <div>
            <Card sx={{ marginBottom: 2.5 }}>
                <CardContent>
                    <Typography variant="h5" fontSize="24px">
                        Dashboard
                    </Typography>
                </CardContent>
            </Card>

            <Button
                onClick={() => navigate('/keyword-clustering')}
                variant="contained"
                color="secondary"
                sx={{ borderWidth: '2px', borderRadius: '6px', marginBottom: '24px' }}
            >
                New project
            </Button>

            <MainCard
                title="Projects"
                secondary={
                    <>
                        <IconButton aria-label="refresh" onClick={refreshProjects} color="primary" sx={{ marginRight: '8px' }}>
                            <Refresh />
                        </IconButton>
                        {selectedRowsData.length > 0 && (
                            <Button
                                onClick={handleDeleteMultipleProjectsClick}
                                sx={{
                                    border: `2px solid ${theme.palette.action.active}`,
                                    borderWidth: '2px',
                                    borderRadius: '12px',
                                    color: theme.palette.action.active
                                }}
                            >
                                Delete
                            </Button>
                        )}
                    </>
                }
            >
                {open && (
                    <>
                        <Dialog
                            open={open}
                            onClose={() => setOpen(false)}
                            slotProps={{ backdrop: { style: { backgroundColor: 'rgba(120,120,120,0.4)' } } }}
                            PaperProps={{
                                elevation: 0
                            }}
                        >
                            <DialogTitle>Confirm Deletion</DialogTitle>
                            <DialogContent>
                                <p>Are you sure you want to delete the selected projects?</p>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpen(false)}>Cancel</Button>
                                <Button onClick={handleDeleteMultipleProjects} color="error">
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
                <Grid>
                    <Projects
                        projects={projects}
                        setProjects={setProjects}
                        isLoading={isLoading}
                        selectedRowsData={selectedRowsData}
                        setSelectedRowsData={setSelectedRowsData}
                    />
                </Grid>
            </MainCard>
        </div>
    );
};

export default Dashboard;
