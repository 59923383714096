import { React, useState, useContext, useEffect } from 'react';
import { UserContext } from 'contexts/user.context';
import { useTheme } from '@mui/material/styles';
import {
    FormControl,
    Typography,
    InputLabel,
    OutlinedInput,
    Grid,
    Stack,
    Button,
    Box,
    FormHelperText,
    Snackbar,
    Alert
} from '@mui/material';
import SubCard from 'ui-component/cards/SubCard';
import Loader from 'ui-component/Loader';

import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    signInAuthUserWithEmailAndPassword,
    changePassword,
    changeEmail,
    updateUserDocDisplayNameEmail,
    getUserDocDisplayName
} from 'utils/firebase/firebase.utils';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

const GeneralAccountSettings = () => {
    const theme = useTheme();
    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();
    const [passwordUpdateSuccess, setPasswordUpdateSuccess] = useState(false);
    const [passwordUpdateFail, setPasswordUpdateFail] = useState(false);
    const [userInfoUpdateSuccess, setUserInfoUpdateSuccess] = useState(false);
    const [userInfoUpdateFail, setUserInfoUpdateFail] = useState(false);
    const [emailUpdateFail, setEmailUpdateFail] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);

    const [userDocRef, setUserDocRef] = useState(null);

    const { currentUser } = useContext(UserContext);
    // console.log('currentUser');

    // console.log(currentUser.providerData[0].providerId);

    const handleNewPasswordSubmit = async (currentPassword, newPassword, confirmNewPassword) => {
        setSubmitting(true);
        if (newPassword !== confirmNewPassword) {
            console.log('new password does not match confirm new password');
        } else {
            try {
                await signInAuthUserWithEmailAndPassword(currentUser.email, currentPassword);
                console.log('current password matches');
                try {
                    await changePassword(newPassword);
                    setPasswordUpdateSuccess(true);
                    console.log('successfully updated password');
                } catch (error) {
                    setPasswordUpdateFail(true);
                    console.log('updating password failed', error);
                }
            } catch (error) {
                setPasswordUpdateFail(true);
                console.log('user sign in failed', error);
            }
        }
        setSubmitting(false);
    };

    const handleUpdateUserInfoSubmit = async (displayName, email) => {
        setSubmitting(true);
        if (!displayName || !email || displayName === '' || email === '') {
            console.log('no display name or email');
        } else {
            try {
                await changeEmail(currentUser, email);
                // TODO: show message that email is updated
                console.log('successfully updated email');

                try {
                    await updateUserDocDisplayNameEmail(currentUser, displayName, email);
                    setUserInfoUpdateSuccess(true);
                    console.log('successfully updated user doc');
                } catch (error) {
                    setUserInfoUpdateFail(true);
                    console.log('updating user doc failed', error);
                }
            } catch (error) {
                setEmailUpdateFail(true);
                console.log('updating email failed', error);
            }
        }
        setSubmitting(false);
    };

    const passwordChangeField = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    const getUserDocRef = async () => {
        const dn = await getUserDocDisplayName(currentUser);
        setUserDocRef(dn);
        // console.log(`userDocRef: ${userDocRef}`);
        setLoading(false);
    };

    useEffect(() => {
        // console.log(currentUser.uid);
        getUserDocRef();
        passwordChangeField('');
    }, []);
    if (isLoading) return <Loader />;

    return (
        <div>
            <Formik
                initialValues={{ displayName: userDocRef ?? '', email: currentUser.email }}
                validationSchema={Yup.object().shape({
                    displayName: Yup.string().max(255).required('Display name is required'),
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
                })}
                onSubmit={(values, actions) => {
                    handleUpdateUserInfoSubmit(values.displayName, values.email);
                }}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <Grid sx={{ marginBottom: 2 }}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4} sx={{ marginBottom: 2 }}>
                                <FormControl fullWidth error={Boolean(props.touched.displayName && props.errors.displayName)}>
                                    <Typography sx={{ marginBottom: 1 }}>Display name</Typography>
                                    <InputLabel shrink="true" htmlFor="outlined-display-name"></InputLabel>
                                    <OutlinedInput
                                        id="outlined-display-name"
                                        type="text"
                                        placeholder="Enter a new display name"
                                        value={props.values.displayName}
                                        name="displayName"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                    />
                                    {props.touched.displayName && props.errors.displayName && (
                                        <FormHelperText error id="standard-weight-helper-text-display-name">
                                            {props.errors.displayName}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth error={Boolean(props.touched.email && props.errors.email)}>
                                    <Typography sx={{ marginBottom: 1 }}>Email</Typography>
                                    <InputLabel shrink="true" htmlFor="outlined-display-name"></InputLabel>
                                    <OutlinedInput
                                        id="outlined-email"
                                        type="email"
                                        placeholder="Enter a new email address"
                                        value={props.values.email}
                                        name="email"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                    />
                                    {props.touched.email && props.errors.email && (
                                        <FormHelperText error id="standard-weight-helper-text-email">
                                            {props.errors.email}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    disabled={isSubmitting}
                                    sx={{ borderWidth: '2px', borderRadius: '6px' }}
                                >
                                    Update
                                </Button>
                            </Box>
                        </Grid>
                    </form>
                )}
            </Formik>
            {currentUser.providerData[0].providerId !== 'google.com' && (
                <SubCard title="Change Password">
                    <Formik
                        initialValues={{ currentPassword: '', newPassword: '', confirmNewPassword: '' }}
                        validationSchema={Yup.object().shape({
                            currentPassword: Yup.string().max(255).required('Current password is required'),
                            newPassword: Yup.string()
                                .min(6, 'Password must be at least 6 characters long')
                                .max(255)
                                .required('Password is required'),
                            confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
                        })}
                        onSubmit={(values, actions) => {
                            handleNewPasswordSubmit(values.currentPassword, values.newPassword, values.confirmNewPassword);
                            values.currentPassword = '';
                            values.newPassword = '';
                            values.confirmNewPassword = '';
                        }}
                    >
                        {(props) => (
                            <form onSubmit={props.handleSubmit}>
                                <Grid sx={{ marginBottom: 2 }}>
                                    <FormControl
                                        fullWidth
                                        sx={{ marginBottom: 2 }}
                                        error={Boolean(props.touched.currentPassword && props.errors.currentPassword)}
                                    >
                                        <Typography sx={{ marginBottom: 1 }}>Current password</Typography>
                                        <InputLabel shrink="true" htmlFor="outlined-current-password"></InputLabel>
                                        <OutlinedInput
                                            id="outlined-current-password"
                                            type="password"
                                            placeholder="Enter your current password"
                                            value={props.values.currentPassword}
                                            name="currentPassword"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                        />
                                        {props.touched.currentPassword && props.errors.currentPassword && (
                                            <FormHelperText error id="standard-weight-helper-text-current-password">
                                                {props.errors.currentPassword}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={4}
                                        sx={{ marginBottom: 2 }}
                                    >
                                        <FormControl fullWidth error={Boolean(props.touched.newPassword && props.errors.newPassword)}>
                                            <Typography sx={{ marginBottom: 1 }}>New password</Typography>
                                            <InputLabel shrink="true" htmlFor="outlined-new-password"></InputLabel>
                                            <OutlinedInput
                                                id="outlined-new-password"
                                                type="password"
                                                placeholder="Enter a new password"
                                                value={props.values.newPassword}
                                                name="newPassword"
                                                onChange={(e) => {
                                                    props.handleChange(e);
                                                    passwordChangeField(e.target.value);
                                                }}
                                                onBlur={props.handleBlur}
                                            />
                                            {props.touched.newPassword && props.errors.newPassword && (
                                                <FormHelperText error id="standard-weight-helper-text-new-password">
                                                    {props.errors.newPassword}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                        <FormControl
                                            fullWidth
                                            error={Boolean(props.touched.confirmNewPassword && props.errors.confirmNewPassword)}
                                        >
                                            <Typography sx={{ marginBottom: 1 }}>Confirm new password</Typography>
                                            <InputLabel shrink="true" htmlFor="outlined-confirm-new-password"></InputLabel>
                                            <OutlinedInput
                                                id="outlined-confirm-new-password"
                                                type="password"
                                                placeholder="Confirm new password"
                                                value={props.values.confirmNewPassword}
                                                name="confirmNewPassword"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                            />
                                            {props.touched.confirmNewPassword && props.errors.confirmNewPassword && (
                                                <FormHelperText error id="standard-weight-helper-text-confirm-new-password">
                                                    {props.errors.confirmNewPassword}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Stack>
                                    {strength !== 0 && (
                                        <FormControl fullWidth>
                                            <Box sx={{ mb: 2 }}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item>
                                                        <Box
                                                            style={{ backgroundColor: level?.color }}
                                                            sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="subtitle1" fontSize="0.75rem">
                                                            {level?.label}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </FormControl>
                                    )}
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            disabled={isSubmitting}
                                            sx={{ borderWidth: '2px', borderRadius: '6px' }}
                                        >
                                            Update Password
                                        </Button>
                                    </Box>
                                    <Snackbar
                                        open={passwordUpdateSuccess}
                                        autoHideDuration={6000}
                                        onClose={() => setPasswordUpdateSuccess(false)}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    >
                                        <Alert onClose={() => setPasswordUpdateSuccess(false)} severity="success" sx={{ width: '100%' }}>
                                            Successfully updated password
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar
                                        open={passwordUpdateFail}
                                        autoHideDuration={6000}
                                        onClose={() => setPasswordUpdateFail(false)}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    >
                                        <Alert onClose={() => setPasswordUpdateFail(false)} severity="error" sx={{ width: '100%' }}>
                                            Password update failed
                                        </Alert>
                                    </Snackbar>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </SubCard>
            )}
            <Snackbar
                open={userInfoUpdateSuccess}
                autoHideDuration={6000}
                onClose={() => setUserInfoUpdateSuccess(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setUserInfoUpdateSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Successfully updated user info
                </Alert>
            </Snackbar>
            <Snackbar
                open={userInfoUpdateFail}
                autoHideDuration={6000}
                onClose={() => setUserInfoUpdateFail(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setUserInfoUpdateFail(false)} severity="error" sx={{ width: '100%' }}>
                    Failed to update user info
                </Alert>
            </Snackbar>
            <Snackbar
                open={emailUpdateFail}
                autoHideDuration={6000}
                onClose={() => setEmailUpdateFail(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setEmailUpdateFail(false)} severity="error" sx={{ width: '100%' }}>
                    Failed to update email
                </Alert>
            </Snackbar>
        </div>
    );
};

export default GeneralAccountSettings;
