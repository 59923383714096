// material-ui
import { Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| SUPPORT PAGE ||============================== //

const SupportPage = () => (
    <MainCard title="Support">
        Please don't hesitate to contact us at <span style={{ color: 'black' }}>help@topclusters.io</span>.
        <Typography variant="body2">We appreciate your feedback!</Typography>
    </MainCard>
);

export default SupportPage;
