import * as React from 'react';
import { Grid, Card, Typography, CardContent, Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import MainCard from 'ui-component/cards/MainCard';
import GeneralAccountSettings from 'ui-component/general-account-settings/general-account-settings.component';
import PaymentHistory from 'ui-component/payment-history/payment-history.component';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const AccountSettings = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Card sx={{ marginBottom: 2.5 }}>
                <CardContent>
                    <Typography variant="h5" fontSize="24px">
                        Account Settings
                    </Typography>
                </CardContent>
            </Card>
            <MainCard
                title={
                    <Tabs value={value} onChange={handleChange} textColor="secondary" indicatorColor="secondary">
                        <Tab label="Account Settings" {...a11yProps(0)} />
                        <Tab label="Payment History" {...a11yProps(1)} />
                        {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                    </Tabs>
                }
            >
                <TabPanel value={value} index={0}>
                    <Grid margin={0} padding={0}>
                        <GeneralAccountSettings />
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <PaymentHistory />
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                    Item Three
                </TabPanel> */}
            </MainCard>
        </>
    );
};

export default AccountSettings;
