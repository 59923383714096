import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from 'ui-component/Loader';

import csvDownload from 'json-to-csv-export';

import value from 'assets/scss/_themes-vars.module.scss';

// material-ui
import { DataGrid } from '@mui/x-data-grid';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert } from '@mui/material';
import { Delete, DeleteOutlined } from '@mui/icons-material';
import { useTheme, makeStyles } from '@mui/material/styles';

import { getProjectsUser, deleteProject } from 'utils/firebase/firebase.utils';
import { UserContext } from '../../contexts/user.context';

const AllOrders = ({ orders, isLoading, setSelectedRowsDataOrders }) => {
    const [selectionModel, setSelectionModel] = useState([]);
    const { currentUser } = useContext(UserContext);

    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);

        const newSelectedRowsData = orders.filter((row) => newSelectionModel.includes(row.createdAt));

        setSelectedRowsDataOrders(newSelectedRowsData);
    };

    const columns = [
        { field: 'userId', headerName: 'User ID', minWidth: 120, flex: 3 },
        { field: 'email', headerName: 'Email', minWidth: 120, flex: 3 },
        { field: 'customerId', headerName: 'Customer ID', minWidth: 120, flex: 3 },
        { field: 'paymentIntentId', headerName: 'Payment Intent ID', minWidth: 120, flex: 3 },
        { field: 'total', headerName: 'Total', minWidth: 80, flex: 1 },
        { field: 'subtotal', headerName: 'Subtotal', minWidth: 80, flex: 1 },
        { field: 'currency', headerName: 'Currency', minWidth: 80, flex: 1 },
        { field: 'payment_status', headerName: 'Payment Status', minWidth: 80, flex: 1 },
        { field: 'status', headerName: 'Status', minWidth: 80, flex: 3 },
        {
            field: 'createdAt',
            headerName: 'Created',
            minWidth: 120,
            flex: 3,
            renderCell: (params) => {
                const dateTime = new Date(params.row.createdAt * 1000);
                console.log(params.row.createdAt);
                const formattedDate = dateTime.toLocaleString('en-US', { timeZone: 'UTC' });
                return <div>{formattedDate}</div>;
            }
        }
    ];

    if (isLoading) return <Loader />;

    return (
        <div style={{ width: '100%' }}>
            <DataGrid
                sx={{
                    border: 0,
                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                        outline: 'none !important'
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
                        outline: 'none'
                    },
                    '.MuiDataGrid-columnSeparator': {
                        display: 'none'
                    },
                    '.MuiDataGrid-columnHeader--sortable': {
                        outline: 'none !important'
                    },
                    '.MuiDataGrid-columnHeaderTitle': {
                        color: 'text.dark'
                    }
                }}
                autoHeight
                disableSelectionOnClick
                rows={orders}
                columns={columns}
                getRowId={(row) => row.createdAt}
                pageSize={10}
                checkboxSelection={true}
                onSelectionModelChange={handleSelectionModelChange}
                selectionModel={selectionModel}
            />
        </div>
    );
};

export default AllOrders;
