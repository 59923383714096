import * as React from 'react';
import { useLocation, Link } from 'react-router-dom';

import { Grid, Card, Typography, CardContent, Box, Tab, Tabs, Button } from '@mui/material';
import PropTypes from 'prop-types';
import MainCard from 'ui-component/cards/MainCard';
import GeneralAccountSettings from 'ui-component/general-account-settings/general-account-settings.component';
import PaymentHistory from 'ui-component/payment-history/payment-history.component';
import ClusterTable from 'ui-component/cluster-table/cluster-table.component';
import { getProject } from 'utils/firebase/firebase.utils';
import { useState, useEffect } from 'react'; // added import statements
import csvDownload from 'json-to-csv-export';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClusterCards from 'ui-component/cluster-cards/cluster-cards.component';
import { fontWeight } from '@mui/system';
import { useTheme } from '@mui/material/styles';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const ClusterReport = () => {
    const theme = useTheme();

    const [value, setValue] = React.useState(0);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const createdAt = searchParams.get('createdAt');
    const createdAtNumber = parseInt(createdAt);

    const userId = searchParams.get('userId');

    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [project, setProject] = useState('');

    const getSelectedProject = async () => {
        const selectedProject = await getProject(createdAtNumber, userId);
        console.log('selectedProject');
        setProject(selectedProject);
        const projectDataParsed = JSON.parse(selectedProject.data.replace(/\bNaN\b/g, '0'));
        setLoading(false);
        return projectDataParsed;
    };

    useEffect(() => {
        async function fetchData() {
            const projectData = await getSelectedProject();
            setData(projectData);
            console.log(projectData);
        }
        fetchData();
        setLoading(false);
        console.log(data);
        console.log(project.projectName);
    }, [createdAtNumber]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const convertData = (projectData) => {
        const dataToConvert = {
            data: projectData,
            filename: 'keyword-clustering',
            delimiter: ','
        };
        // console.log(projectData);
        return dataToConvert;
    };

    return (
        <>
            <Card sx={{ marginBottom: 2.5 }}>
                <CardContent>
                    <Typography variant="h5" fontSize="24px">
                        Clustering Report
                    </Typography>
                </CardContent>
            </Card>
            <Link to="/dashboard">
                {/* <ArrowBackIcon></ArrowBackIcon> */}
                <Button sx={{ marginBottom: '8px' }}>Back to dashboard</Button>
            </Link>
            <MainCard
                title={
                    <Tabs value={value} onChange={handleChange} textColor="secondary" indicatorColor="secondary">
                        <Tab label="Table" {...a11yProps(0)} />
                        <Tab label="Cards" {...a11yProps(1)} />
                        {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                    </Tabs>
                }
                secondary={
                    <div>
                        {' '}
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                csvDownload(convertData(data));
                            }}
                            variant="outlined"
                            color="secondary"
                            sx={{ borderWidth: '2px', borderRadius: '12px' }}
                        >
                            Export
                        </Button>
                    </div>
                }
            >
                <TabPanel value={value} index={0}>
                    <Typography variant="h4" marginBottom={2} sx={{ color: theme.palette.grey[800] }}>
                        Project name: <span style={{ fontWeight: 'normal' }}>{project.projectName}</span>
                    </Typography>
                    <Grid margin={0} padding={0}>
                        <ClusterTable data={data} setData={setData} isLoading={isLoading} />
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Typography variant="h4" marginBottom={4} sx={{ color: theme.palette.grey[800] }}>
                        Project name: <span style={{ fontWeight: 'normal' }}>{project.projectName}</span>
                    </Typography>
                    <ClusterCards data={data} isLoading={isLoading} />
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                    Item Three
                </TabPanel> */}
            </MainCard>
        </>
    );
};

export default ClusterReport;
