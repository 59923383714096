import { Grid, Card, Typography, CardContent, Button, Box, Paper, Stack, useMediaQuery } from '@mui/material';
import AuthCardWrapper from 'views/pages/authentication/AuthCardWrapper';
import AuthWrapper1 from 'views/pages/authentication/AuthWrapper1';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import { useState, useContext, useEffect } from 'react';
import { UserContext } from 'contexts/user.context';
import { useNavigate } from 'react-router-dom';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { verificationEmail } from 'utils/firebase/firebase.utils';

const VerifyEmail = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const navigation = useNavigate();
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const { currentUser } = useContext(UserContext);

    const submitVerifyEmail = async () => {
        setSubmitting(true);
        const response = await verificationEmail();
        // console.log(`${response}`);
        if (response === 'Sent') {
            setError(false);
            setSent(true);
        } else if (response === 'Error') {
            setSent(false);
            setError(true);
        } else if (response === 'No user') {
            setSent(false);
            setError(true);
        }
        setSubmitting(false);
    };

    useEffect(() => {
        if (currentUser && currentUser.emailVerified !== null) {
            if (currentUser.emailVerified === true) {
                navigation('/dashboard');
            }
        }
    }, [currentUser]);

    return (
        <>
            <AuthWrapper1>
                <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                <AuthCardWrapper
                                    sx={{
                                        width: '400px',
                                        [theme.breakpoints.down('sm')]: {
                                            width: '100%'
                                        }
                                    }}
                                >
                                    <Grid container direction="column" justifyContent="center" spacing={1}>
                                        <Grid item xs={12} alignItems="center" justifyContent="start">
                                            <Box sx={{ mb: 2 }}>
                                                <Typography variant={matchDownSM ? 'h4' : 'h3'}>Verify email address</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {sent && (
                                        <Grid container direction="column" justifyContent="center" spacing={1}>
                                            <Grid item xs={12} alignItems="center" justifyContent="start">
                                                <Box sx={{ mb: 2 }}>
                                                    <Paper sx={{ backgroundColor: theme.palette.success.light, p: 2 }}>
                                                        <Typography
                                                            variant={matchDownSM ? 'h6' : 'h5'}
                                                            sx={{ color: theme.palette.success.dark }}
                                                        >
                                                            A new verification email has been sent.
                                                        </Typography>
                                                    </Paper>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {error && (
                                        <Grid container direction="column" justifyContent="center" spacing={1}>
                                            <Grid item xs={12} alignItems="center" justifyContent="start">
                                                <Box sx={{ mb: 2 }}>
                                                    <Paper sx={{ backgroundColor: theme.palette.error.light, p: 2 }}>
                                                        <Typography
                                                            variant={matchDownSM ? 'h6' : 'h5'}
                                                            sx={{ color: theme.palette.error.dark }}
                                                        >
                                                            Error sending new verification email. Try logging in or registering again.
                                                        </Typography>
                                                    </Paper>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Typography variant="subtitle1" sx={{ textDecoration: 'none' }}>
                                        We've sent a verification email to your email address.
                                    </Typography>
                                    <Typography variant="body2" sx={{ textDecoration: 'none', paddingY: '4px' }}>
                                        Please check your spam folder and if you didn't receive the email, you can re-send it.
                                    </Typography>

                                    <Box sx={{ mt: 2 }}>
                                        <AnimateButton>
                                            <Button
                                                disableElevation
                                                disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="outlined"
                                                color="secondary"
                                                onClick={submitVerifyEmail}
                                            >
                                                Resend Email
                                            </Button>
                                        </AnimateButton>
                                    </Box>

                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Grid item xs={12}>
                                            <Grid item container direction="row" alignItems="center" justifyContent="center" xs={12} mt={1}>
                                                <Typography variant="subtitle1" sx={{ textDecoration: 'none' }}>
                                                    Have an account?
                                                </Typography>
                                                <Button sx={{ textDecoration: 'none' }} onClick={() => navigation('/auth/login')}>
                                                    <Typography variant="subtitle1" color="secondary" sx={{ cursor: 'pointer' }}>
                                                        back to Log In
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </AuthCardWrapper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AuthWrapper1>
        </>
    );
};

export default VerifyEmail;
