import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const CardStyle = styled(Card)(({ theme, clicked }) => ({
    background: clicked ? theme.palette.primary.light : theme.palette.grey[100],
    marginBottom: '16px',
    overflow: 'hidden',
    position: 'relative',
    border: clicked ? `1px solid ${theme.palette.primary.main}` : 'none',

    '&:hover': {
        cursor: 'pointer',
        background: theme.palette.primary.light,
        border: `2px solid ${theme.palette.primary.main}`,
        borderColor: theme.palette.primary.main,
        boxShadow: '0 0 8px 0 rgba(0,0,0,0.2)'
    }
}));

const AlgorithmCard = ({ selected, onClick, title, speed, limit, description }) => {
    const theme = useTheme();
    const [clicked, setClicked] = useState(false);
    const [showMore, setShowMore] = useState(false);

    const customization = useSelector((state) => state.customization);

    const handleClick = () => {
        setClicked(!clicked);
        onClick();
    };

    return (
        <CardStyle clicked={selected} onClick={handleClick}>
            <CardContent>
                <Grid container direction="column" spacing={2}>
                    <Grid container direction="row" justifyContent="space-between" paddingLeft={2} paddingTop={2}>
                        <Grid item>
                            <Typography variant="h4">{title}</Typography>
                        </Grid>
                        {speed !== '' && (
                            <Grid item>
                                <Typography
                                    sx={{
                                        backgroundColor: theme.palette.grey[50],
                                        paddingY: '4px',
                                        paddingX: '8px',
                                        color: theme.palette.grey[900],
                                        borderColor: theme.palette.grey[600] + 75,
                                        borderRadius: `${customization.borderRadius}px`,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                    variant="p"
                                >
                                    <AccessTimeIcon fontSize="small" sx={{ paddingRight: '4px' }} />
                                    {speed}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" color="grey.900">
                            {limit}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" color="grey.900" sx={{ opacity: 0.6 }}>
                            <Typography variant="subtitle2" color="grey.900" sx={{ opacity: 0.6 }}>
                                {showMore ? description : description.substring(0, description.indexOf('.', description.indexOf('.')))}{' '}
                            </Typography>
                        </Typography>
                        <Typography variant="subtitle2" color="primary" sx={{ cursor: 'pointer' }} onClick={() => setShowMore(!showMore)}>
                            {showMore ? 'Read less' : 'Read more'}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </CardStyle>
    );
};

export default AlgorithmCard;
