// assets
import { IconDashboard } from '@tabler/icons';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'keyword-clustering-page',
            title: 'Keyword Clustering',
            type: 'item',
            url: '/keyword-clustering',
            icon: HubOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'buy-credits-page',
            title: 'Buy Credits',
            type: 'item',
            url: '/buy-credits',
            icon: AttachMoneyIcon,
            breadcrumbs: false
        },
        {
            id: 'account-settings-page',
            title: 'Account Settings',
            type: 'item',
            url: '/account-settings',
            icon: ManageAccountsIcon,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
