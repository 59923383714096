// material-ui
import { Typography } from '@mui/material';
import Faq from 'ui-component/faq/faq.component';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| SUPPORT PAGE ||============================== //

const FaqPage = () => <Faq />;

export default FaqPage;
