// assets
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const keywordClustering = {
    id: 'keyword-clustering',
    type: 'group',
    children: [
        {
            id: 'keyword-clustering-page',
            title: 'Keyword Clustering',
            type: 'item',
            url: '/keyword-clustering',
            icon: HubOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default keywordClustering;
