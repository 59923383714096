import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthFooter from 'ui-component/cards/AuthFooter';
import { resetPassword } from 'utils/firebase/firebase.utils';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    ButtonBase,
    Checkbox,
    Divider,
    Paper,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';

const ResetPassword = ({ ...others }) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const navigation = useNavigate();
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const submitResetPassword = async (email) => {
        setSubmitting(true);
        const response = await resetPassword(email);
        console.log(`${response}`);
        if (response === 'Password reset email sent') {
            setError(false);
            setSent(true);
        } else {
            setSent(false);
            setError(true);
        }
        setSubmitting(false);
    };

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper
                                sx={{
                                    width: '400px',
                                    [theme.breakpoints.down('sm')]: {
                                        width: '100%'
                                    }
                                }}
                            >
                                {' '}
                                <Grid container direction="column" justifyContent="center" spacing={1}>
                                    <Grid item xs={12} alignItems="center" justifyContent="start">
                                        <Box sx={{ mb: 2 }}>
                                            <Typography variant={matchDownSM ? 'h4' : 'h3'}>Reset password</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {sent && (
                                    <Grid container direction="column" justifyContent="center" spacing={1}>
                                        <Grid item xs={12} alignItems="center" justifyContent="start">
                                            <Box sx={{ mb: 2 }}>
                                                <Paper sx={{ backgroundColor: theme.palette.success.light, p: 2 }}>
                                                    <Typography
                                                        variant={matchDownSM ? 'h6' : 'h5'}
                                                        sx={{ color: theme.palette.success.dark }}
                                                    >
                                                        A password reset link has been sent!
                                                    </Typography>
                                                </Paper>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                                {error && (
                                    <Grid container direction="column" justifyContent="center" spacing={1}>
                                        <Grid item xs={12} alignItems="center" justifyContent="start">
                                            <Box sx={{ mb: 2 }}>
                                                <Paper sx={{ backgroundColor: theme.palette.error.light, p: 2 }}>
                                                    <Typography
                                                        variant={matchDownSM ? 'h6' : 'h5'}
                                                        sx={{ color: theme.palette.error.dark }}
                                                    >
                                                        Password reset failed.
                                                    </Typography>
                                                </Paper>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                                <Formik
                                    initialValues={{
                                        email: ''
                                    }}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
                                    })}
                                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                        console.log(values);
                                        submitResetPassword(values.email);
                                    }}
                                >
                                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                        <form onSubmit={handleSubmit} {...others}>
                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.email && errors.email)}
                                                sx={{ ...theme.typography.customInput }}
                                            >
                                                <InputLabel htmlFor="outlined-adornment-email-login">Email Address</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-email-login"
                                                    type="email"
                                                    value={values.email}
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Email Address / Username"
                                                    inputProps={{}}
                                                    sx={{ width: '100%' }}
                                                />
                                                {touched.email && errors.email && (
                                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                                        {errors.email}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                            <Box sx={{ mt: 2 }}>
                                                <AnimateButton>
                                                    <Button
                                                        disableElevation
                                                        // disabled={isSubmitting}
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                        color="secondary"
                                                    >
                                                        Reset Password
                                                    </Button>
                                                </AnimateButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                                <Stack direction="row" alignItems="center" spacing={1} xs={12}>
                                    <Grid item xs={12}>
                                        <Grid item container direction="row" alignItems="center" justifyContent="center" xs={12} mt={1}>
                                            <Typography variant="subtitle1" sx={{ textDecoration: 'none' }}>
                                                or
                                            </Typography>
                                            <Button sx={{ textDecoration: 'none' }} onClick={() => navigation('/auth/login')}>
                                                <Typography variant="subtitle1" color="secondary" sx={{ cursor: 'pointer' }}>
                                                    back to Log In
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default ResetPassword;
