import { React, useContext, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../contexts/user.context';
import Loader from 'ui-component/Loader';
import { useNavigate } from 'react-router-dom';
import Admin from 'views/admin/admin.component';

const ProtectedRoute = ({ permission, ...props }) => {
    const { currentUser } = useContext(UserContext);

    if (!currentUser) {
        return <Navigate to="/auth/login" />;
    }

    if (permission === 'admin' && currentUser.email !== 'joffenbratli@gmail.com') {
        return <Navigate to="/" replace />;
    }

    if (permission === 'admin') {
        return <Admin {...props} />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
