import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Loader from 'ui-component/Loader';
import { DataGrid } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { v4 as uuid } from 'uuid';
import { getProject } from 'utils/firebase/firebase.utils';
import Projects from 'ui-component/projects/projects.component';
import { Typography } from '@mui/material';

const ClusterTable = ({ data, setData, isLoading }) => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [isRowExpanded, setIsRowExpanded] = useState(false);
    const [expandedRowId, setExpandedRowId] = useState(null);

    const columns = [
        { field: 'cluster', headerName: 'Cluster', minWidth: 150, flex: 1, sortable: false },
        {
            field: 'keyword',
            headerName: 'Keywords',
            minWidth: 150,
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                const keyword = params.row.keyword;

                const handleClick = (e) => {
                    e.stopPropagation();

                    if (!params.row.isExpanded) {
                        setIsRowExpanded(true);
                        setExpandedRowId(params.row.id);
                    }

                    if (params.row.isExpanded) {
                        setIsRowExpanded(false);
                        setExpandedRows([]);
                        setExpandedRowId(null); // reset the expanded row's ID
                    } else {
                        setExpandedRowId(null); // reset the expanded row's ID

                        const newRows =
                            params.row.keywords?.map((keyword, index) => ({
                                id: uuid(),
                                cluster: '',
                                keyword,
                                totalMonthlyVolume: params.row.totalMonthlyVolume,
                                isExpanded: false,
                                parentId: params.row.id
                            })) || [];

                        setExpandedRows([...newRows]);
                    }
                };

                return (
                    <div>
                        {keyword}
                        {/* show the ExpandMoreIcon only if the row is not one of the new rows and the expandedRowId is null or not equal to the current row's id */}
                        {!params.row.parentId && (expandedRowId === null || expandedRowId !== params.row.id) && params.row.cluster && (
                            <>
                                {params.row.isExpanded ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <ExpandLessIcon onClick={handleClick} color="primary" />
                                        <Typography> {params.row.keywords ? params.row.keywords.length : 0} </Typography>
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <ExpandMoreIcon onClick={handleClick} color="inherit" />
                                        <Typography> {params.row.keywords ? params.row.keywords.length : 0} </Typography>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                );
            }
        },
        {
            field: 'totalMonthlyVolume',
            headerName: 'Total Volume',
            minWidth: 150,
            sortable: false
        }
    ];

    const originalRowsOld = [
        {
            id: 1,
            cluster: 'Cluster 1',
            keywords: ['Keyword 1', 'Keyword 2', 'Keyword 3'],
            totalMonthlyVolume: 10000
        },
        {
            id: 2,
            cluster: 'Cluster 2',
            keywords: ['Keyword 4', 'Keyword 5', 'Keyword 6'],
            totalMonthlyVolume: 20000
        }
    ];

    if (isLoading) return <Loader />;

    if (data) {
        const originalRows = data.map((item, index) => ({
            id: index,
            cluster: item.Cluster,
            keywords: item.Keywords.split(', '),
            totalMonthlyVolume: item['Total monthly volume']
        }));
        const rows = originalRows.flatMap((row) => {
            const isRowExpanded = expandedRows.some((r) => r.parentId === row.id);

            return [
                {
                    ...row,
                    isExpanded: isRowExpanded,
                    hasExpandableRows: row.keywords?.length > 1
                },
                ...(isRowExpanded
                    ? expandedRows
                          .filter((r) => r.parentId === row.id)
                          .map((r) => ({
                              ...r,
                              isExpanded: true,
                              hasExpandableRows: false,
                              totalMonthlyVolume: ''
                          }))
                    : [])
            ];
        });

        return (
            <div style={{ width: '100%' }}>
                <DataGrid
                    sx={{
                        border: 0,
                        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                            outline: 'none !important'
                        },
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
                            outline: 'none'
                        },
                        '.MuiDataGrid-columnSeparator': {
                            display: 'none'
                        },
                        '.MuiDataGrid-columnHeader--sortable': {
                            outline: 'none !important'
                        },
                        '.MuiDataGrid-columnHeaderTitle': {
                            color: 'text.dark'
                        }
                    }}
                    autoHeight
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnMenu
                    rows={rows}
                    columns={columns}
                />
            </div>
        );
    } else {
        <div>Error fetching clusters</div>;
    }
};

export default ClusterTable;
