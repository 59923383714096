import { React } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import MainLayout from 'layout/MainLayout';
import Dashboard from 'views/dashboard';
import SupportPage from 'views/support-page';
import Login from 'views/pages/authentication/authentication3/Login3';
import ProtectedRoute from 'protected-route/ProtectedRoute';
import MinimalLayout from 'layout/MinimalLayout';
import Register from 'views/pages/authentication/authentication3/Register3';
import KeywordClustering from 'views/keyword-clustering/keyword-clustering.component';
import ResetPassword from 'views/pages/authentication/authentication3/ResetPassword';
import AccountSettings from 'views/account-settings/account-settings.component';
import BuyCredits from 'views/buy-credits/buy-credits.component';
import CheckoutSuccess from 'views/checkout-success/checkout-success.component';
import CheckoutCancelled from 'views/checkout-cancelled/checkout-cancelled.component';
import VerifyEmail from 'views/verify-email/verify-email.component';
import FaqPage from 'views/faq-page';
import SampleReportsPage from 'views/sample-reports-page';
import ClusterReport from 'views/report/report';
import Admin from 'views/admin/admin.component';
const MainRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<ProtectedRoute />}>
                <Route path="/" element={<MainLayout />}>
                    <Route path="/" element={<Navigate replace to="dashboard" />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="keyword-clustering" element={<KeywordClustering />} />
                    <Route path="account-settings" element={<AccountSettings />} />
                    <Route path="buy-credits" element={<BuyCredits />} />
                    <Route path="checkout-success" element={<CheckoutSuccess />} />
                    <Route path="checkout-cancelled" element={<CheckoutCancelled />} />
                    <Route path="support-page" element={<SupportPage />} />
                    <Route path="faq-page" element={<FaqPage />} />
                    <Route path="sample-reports" element={<SampleReportsPage />} />
                    <Route path="cluster-report" element={<ClusterReport />} />
                    <Route
                        path="admin"
                        element={
                            <ProtectedRoute permission="admin">
                                <Admin />
                            </ProtectedRoute>
                        }
                    />
                </Route>
            </Route>
            {/* <Route
                element={
                    <ProtectedRoute>
                        <Admin />
                    </ProtectedRoute>
                }
            /> */}

            <Route path="auth" element={<MinimalLayout />}>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="verify-email" element={<VerifyEmail />} />
            </Route>
        </Routes>
    );
};

export default MainRoutes;
