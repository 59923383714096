import { useState, useContext } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { UserContext } from '../../contexts/user.context';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import PricingOption from 'ui-component/pricing-option/pricing-option.component';
import { Button, Grid, List, ListItem, ListItemText, ListItemIcon, Box, Typography } from '@mui/material';
import { padding } from '@mui/system';

const PaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const theme = useTheme();

    const { currentUser } = useContext(UserContext);
    const { uid, email } = currentUser;

    const testPlans = [
        {
            name: 'Basic',
            price: 8.99,
            features: ['1,000 Keywords', '$8.99 / 1000 Keywords', 'Downloadable Report', 'Credits Never Expire'],
            prodId: 'prod_NPuVmTOnaDxe8s'
        },
        {
            name: 'Standard',
            price: 18.99,
            features: ['2,500 Keywords', '$7.60 / 1000 Keywords', 'Downloadable Report', 'Credits Never Expire'],
            prodId: 'prod_NPuWTTaaB3Qez0'
        },
        {
            name: 'Pro',
            price: 32.99,
            features: ['5,000 Keywords', '$6.60 / 1000 Keywords', 'Downloadable Report', 'Credits Never Expire'],
            prodId: 'prod_NPuX9vvOvLnzlI'
        },
        {
            name: 'Premium',
            price: 59.99,
            features: ['10,000 Keywords', '$5.99 / 1000 Keywords', 'Downloadable Report', 'Credits Never Expire'],
            prodId: 'prod_NPuYzfvb4Z1Cwh'
        }
    ];

    const plans = [
        // {
        //     name: 'Test',
        //     price: 1.0,
        //     features: ['Test'],
        //     prodId: 'prod_NZbqmfMWNVaSxo'
        // },
        {
            name: 'Basic',
            price: 8.99,
            features: ['1,000 Keywords', '$8.99 / 1000 Keywords', 'Downloadable Report', 'Credits Never Expire'],
            prodId: 'prod_NZblVIM1Zquiuo'
        },
        {
            name: 'Standard',
            price: 18.99,
            features: ['2,500 Keywords', '$7.60 / 1000 Keywords', 'Downloadable Report', 'Credits Never Expire'],
            prodId: 'prod_NZbniEerAEhWNB'
        },
        {
            name: 'Pro',
            price: 32.99,
            features: ['5,000 Keywords', '$6.60 / 1000 Keywords', 'Downloadable Report', 'Credits Never Expire'],
            prodId: 'prod_NZboY48Fd7VOPo'
        },
        {
            name: 'Premium',
            price: 59.99,
            features: ['10,000 Keywords', '$5.99 / 1000 Keywords', 'Downloadable Report', 'Credits Never Expire'],
            prodId: 'prod_NZbpQ37wYbRyrW'
        }
    ];

    const checkoutHandler = async (productName, unit_amount, prodId) => {
        // e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        const url = `${process.env.REACT_APP_STRIPE_CLOUD_FUNCTION_URL}/create-checkout-session`;
        const response = await fetch(url, {
            method: 'post',
            headers: {
                // 'Content-Type': 'application/json'
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: currentUser.uid,
                email: currentUser.email,
                productName: productName,
                unit_amount: unit_amount,
                prodId: prodId
            })
        });
        const resBody = await response.json();
        window.location.href = resBody.url;
    };

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid container item spacing={0}>
                    {plans.map((plan, index) => (
                        <PricingOption key={index} {...plan} checkoutHandler={checkoutHandler} />
                    ))}
                </Grid>
            </Grid>
        </div>
    );
};
export default PaymentForm;
