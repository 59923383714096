import { Grid, Button, Card, Typography, CardContent } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { ReactComponent as CheckoutCancelIcon } from '../../assets/images/checkout-cancel.svg';

const CheckoutCancelled = () => {
    return (
        <div>
            {/* <Card sx={{ marginBottom: 2.5 }}>
                <CardContent>
                    <Typography variant="h5" fontSize="24px">
                        Checkout cancelled
                    </Typography>
                </CardContent>
            </Card> */}
            <MainCard title="Something went wrong">
                <Grid container direction="column" margin={0} padding={0} alignItems="center" justifyContent="center">
                    <CheckoutCancelIcon height={200} width={200} />
                    <Typography variant="body1" fontSize="16px" textAlign="center" p={1}>
                        We're sorry to hear that there was an issue with your payment. If you experienced any issues during the checkout
                        process, please let us know by contacting our support team at help@topclusters.io.
                    </Typography>
                    <Typography variant="body1" fontSize="16px" textAlign="center" p={1}>
                        If you would like to try again, please return to the buy credits page, choose a product and submit your payment
                        again. Please double-check your payment details and ensure that all information is correct.
                    </Typography>
                    <Typography variant="body1" fontSize="16px" textAlign="center" p={1}>
                        If you continue to experience issues, please contact our support team for further assistance. We'll do our best to
                        resolve the issue as quickly as possible so that you can take advantage of our services.
                    </Typography>
                    <Typography variant="body1" fontSize="16px" textAlign="center" p={1}>
                        Thank you for considering our product, and we apologize for any inconvenience this may have caused.
                    </Typography>
                </Grid>
            </MainCard>
        </div>
    );
};

export default CheckoutCancelled;
