// assets
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const accountSettings = {
    id: 'account-settings',
    type: 'group',
    children: [
        {
            id: 'account-settings-page',
            title: 'Account Settings',
            type: 'item',
            url: '/account-settings',
            icon: ManageAccountsIcon,
            breadcrumbs: false
        }
    ]
};

export default accountSettings;
