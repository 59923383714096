// assets
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const buyCredits = {
    id: 'buy-credits',
    type: 'group',
    children: [
        {
            id: 'buy-credits-page',
            title: 'Buy Credits',
            type: 'item',
            url: '/buy-credits',
            icon: AttachMoneyIcon,
            breadcrumbs: false
        }
    ]
};

export default buyCredits;
