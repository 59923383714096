import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';
import { ReactComponent as LogoTopClusters } from '../../../assets/images/top-clusters-logo-text-lower-v03.svg';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        <LogoTopClusters className="logo" height={45} width={160} />
    </ButtonBase>
);

export default LogoSection;
