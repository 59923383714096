// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// constant
const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const admin = {
    id: 'admin-pagegroup',
    type: 'admin',
    children: [
        {
            id: 'admin-page',
            title: 'Admin',
            type: 'item',
            url: '/admin',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        }
    ]
};

export default admin;
