import dashboard from './dashboard';
import support from './support';
import pages from './pages';
import keywordClustering from './keyword-clustering';
import accountSettings from './account-settings';
import buyCredits from './buy-credits';
import admin from './admin';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, support, admin]
};

export default menuItems;
