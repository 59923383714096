import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from 'ui-component/Loader';

import csvDownload from 'json-to-csv-export';

import value from 'assets/scss/_themes-vars.module.scss';

// material-ui
import { DataGrid } from '@mui/x-data-grid';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert } from '@mui/material';
import { Delete, DeleteOutlined } from '@mui/icons-material';
import { useTheme, makeStyles } from '@mui/material/styles';

import { getProjectsUser, deleteProject } from 'utils/firebase/firebase.utils';
import { UserContext } from '../../contexts/user.context';

const Projects = ({ projects, setProjects, isLoading, selectedRowsData, setSelectedRowsData }) => {
    const [open, setOpen] = useState(false);
    const [createdAtToDelete, setCreatedAtToDelete] = useState();
    const [projectNameToDelete, setProjectNameToDelete] = useState();
    const [selectionModel, setSelectionModel] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);

    const convertData = (projectData) => {
        const dataToConvert = {
            data: projectData,
            filename: 'keyword-clustering',
            delimiter: ','
        };
        // console.log(projectData);
        return dataToConvert;
    };

    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);

        const newSelectedRowsData = projects.filter((row) => newSelectionModel.includes(row.createdAt));

        setSelectedRowsData(newSelectedRowsData);
    };

    const columns = [
        { field: 'projectName', headerName: 'Project name', minWidth: 120, flex: 3 },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 100,
            flex: 2,
            renderCell: (params) => {
                if (params.row.status === 'Completed')
                    return <div style={{ color: value.successDark, fontWeight: 'bold' }}>{params.row.status}</div>;
                if (params.row.status === 'In progress')
                    return <div style={{ color: value.warningDark, fontWeight: 'bold' }}>{params.row.status}</div>;
                if (params.row.status === 'Failed')
                    return <div style={{ color: value.errorMain, fontWeight: 'bold' }}>{params.row.status}</div>;
            }
        },
        { field: 'numKeywords', headerName: 'Keywords', minWidth: 80, flex: 2 },
        {
            field: 'createdAt',
            headerName: 'Created',
            minWidth: 120,
            flex: 3,
            renderCell: (params) => {
                const dateTime = new Date(params.row.createdAt);
                const formattedDate = dateTime.toLocaleString('en-US', { timeZone: 'UTC' });
                return <div>{formattedDate}</div>;
            }
        },

        {
            field: 'open',
            headerName: '',
            minWidth: 120,
            align: 'center',
            flex: 2,
            sortable: false,
            renderCell: (params) => {
                if (params.row.status === 'Completed') {
                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ borderWidth: '2px', borderRadius: '12px' }}
                            onClick={() => {
                                const projectCreatedAt = params.row.createdAt;
                                console.log(projectCreatedAt);
                                console.log(currentUser.uid);
                                navigate(`/cluster-report?createdAt=${projectCreatedAt}&userId=${currentUser.uid}`);
                            }}
                        >
                            Open Report
                        </Button>
                    );
                } else {
                    return <div></div>;
                }
            }
        },
        {
            field: 'download',
            headerName: '',
            minWidth: 20,
            align: 'center',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                if (params.row.status === 'Completed') {
                    const projectData = params.row.data;
                    const projectDataParsed = JSON.parse(projectData.replace(/\bNaN\b/g, '0'));
                    return (
                        <IconButton
                            aria-label="download"
                            onClick={(e) => {
                                e.stopPropagation();
                                csvDownload(convertData(projectDataParsed));
                            }}
                        >
                            <FileDownloadIcon color="action" />
                        </IconButton>
                        // <Button
                        //     onClick={(e) => {
                        //         e.stopPropagation();
                        //         csvDownload(convertData(projectDataParsed));
                        //     }}
                        //     variant="outlined"
                        //     color="secondary"
                        //     sx={{ borderWidth: '2px', borderRadius: '12px' }}
                        // >
                        //     Download
                        // </Button>
                    );
                } else {
                    return <div></div>;
                }
            }
        },
        {
            field: 'delete',
            headerName: '',
            minWidth: 20,
            align: 'center',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                const handleDeleteClick = (e) => {
                    e.stopPropagation();
                    setCreatedAtToDelete(params.row.createdAt);
                    setProjectNameToDelete(params.row.projectName);
                    setOpen(true);
                };
                const handleDelete = async () => {
                    await deleteProject(projectNameToDelete, createdAtToDelete);

                    setProjects(
                        projects.filter((project) => {
                            return !(project.projectName === projectNameToDelete && project.createdAt === createdAtToDelete);
                        })
                    );

                    setOpen(false);
                };
                return (
                    <>
                        <IconButton aria-label="delete" onClick={handleDeleteClick}>
                            <DeleteOutlined color="action" />
                        </IconButton>
                        <Dialog
                            open={open}
                            onClose={() => setOpen(false)}
                            slotProps={{ backdrop: { style: { backgroundColor: 'rgba(120,120,120,0.05)' } } }}
                            PaperProps={{
                                elevation: 0
                            }}
                        >
                            <DialogTitle>Confirm Deletion</DialogTitle>
                            <DialogContent>
                                <p>Are you sure you want to delete this project?</p>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpen(false)}>Cancel</Button>
                                <Button onClick={handleDelete} color="error">
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                );
            }
        }
    ];

    if (isLoading) return <Loader />;

    return (
        <div style={{ width: '100%' }}>
            <DataGrid
                sx={{
                    border: 0,
                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                        outline: 'none !important'
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
                        outline: 'none'
                    },
                    '.MuiDataGrid-columnSeparator': {
                        display: 'none'
                    },
                    '.MuiDataGrid-columnHeader--sortable': {
                        outline: 'none !important'
                    },
                    '.MuiDataGrid-columnHeaderTitle': {
                        color: 'text.dark'
                    }
                }}
                autoHeight
                disableSelectionOnClick
                rows={projects}
                columns={columns}
                getRowId={(row) => row.createdAt}
                pageSize={10}
                rowsPerPageOptions={[10]}
                // onRowClick={(row) => {
                //     setSelectedRows(row.id);
                // }}
                checkboxSelection={true}
                onSelectionModelChange={handleSelectionModelChange}
                selectionModel={selectionModel}
            />
        </div>
    );
};

export default Projects;
