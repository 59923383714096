import styled from 'styled-components';
import theme from 'themes';

export const FaqContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const FaqContent = styled.div`
    // height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: white;
    border-radius: 12px;
`;

export const FaqTitle = styled.h2`
    width: 80%;
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
    color: #212121;
    padding: 12px 0 0 0;
    margin-bottom: 12px;
    @media screen and (max-width: 768px) {
        font-size: 3rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.5rem;
    }
`;

export const FaqSubtitle = styled.p`
    width: 50%;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    color: #424242;
    padding-bottom: 12px;
    @media screen and (max-width: 480px) {
        font-size: 1rem;
    }
`;

export const FaqItemsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 56px;
`;
