import { createContext, useState, useEffect } from 'react';
import { onAuthStateChangedListener, createUserDocumentFromAuth } from '../utils/firebase/firebase.utils';
import Loader from 'ui-component/Loader';

export const UserContext = createContext({
    // setCurrentUser: () => null,
    // currentUser: null
});

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const value = { currentUser, setCurrentUser };
    const [pending, setPending] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChangedListener((user) => {
            if (user) {
                createUserDocumentFromAuth(user);
            }
            setCurrentUser(user);
            setPending(false);
        });
        // console.log(currentUser.email);

        return unsubscribe;
    }, []);

    if (pending) {
        return <Loader />;
    }

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
